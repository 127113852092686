import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthConsumer } from "shared/contexts/AuthContext";

export default function ProtectedRoute(props: any) {
  return (
    <AuthConsumer>
      {({ isAuth }) => (isAuth ? <Outlet /> : <Navigate to={"/"} />)}
    </AuthConsumer>
  );
}
