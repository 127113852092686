import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
//RouteConstants
import { ADMIN } from "shared/constants/RouteConstants";

//ProtectedRoute
import ProtectedRoute from "shared/components/ProtectedRoute";

//Views
import AdminDashboard from "shared/view/AdminDashboard/AdminDashboard";
import AdminRoutes from "views/AdminRoutes";
import Login from "views/Auth/login/Login";
import Page404 from "views/Page404/Page404";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login location={"/"} />,
    errorElement: <Page404 />,
  },

  {
    path: "/",
    element: <ProtectedRoute></ProtectedRoute>,
    errorElement: <Page404 />,
    children: [
      {
        path: ADMIN,
        element: <AdminRoutes />,
        errorElement: <Page404 />,
        children: [
          {
            path: ADMIN,
            element: <AdminDashboard location={ADMIN} />,
            errorElement: <Page404 />,
          },
        ],
      },
    ],
  },
]);
