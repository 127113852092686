import * as React from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import avatar from "client/src/assests/images/avatarPlaceholder.jpeg";
import type { MenuProps } from "antd";
import { useState } from "react";
import "./index.scss";
import logo2 from "client/src/assests/images/logo2.svg";

import {
  AppstoreOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

import { ADMIN, USER_MANGEMENT } from "../../../constants/RouteConstants";
import { logout } from "../../../contexts/AuthContext";
import useWindowDimensions from "../../../hooks/WindowsDimension";

const { Title } = Typography;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  // getItem("Dashboard", `${ADMIN}`, <AppstoreOutlined />),
  getItem("User Management", `${ADMIN}`, <AppstoreOutlined />),
  // getItem(
  //   "Banner Management",
  //   "banner",
  //   <AppstoreOutlined className="admin-sidebar-icon" />,
  //   [
  //     getItem(
  //       "Home Slider",
  //       `${BANNER_LIST}`,
  //       <AppstoreOutlined className="admin-sidebar-icon" />
  //     ),
  //   ]
  // ),
];

interface LayoutProps {
  children: any;
  title?: any;
  titleIcon?: any;
  titleSearch?: any;
  titleAction?: any;
  onMenuClick?: (route: string) => void;
  panel?: any;
  pagination: any;
  filters: any;
  location: string;
}

const { Header, Footer, Sider, Content } = Layout;

export default function AdminLayout({
  children,
  titleSearch,
  titleIcon,
  titleAction,
  title,
  panel,
  pagination,
  filters,
  location,
}: LayoutProps) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const showVisible = () => {
    if (visible) setVisible(false);
    else setVisible(true);
  };
  const windowDimensions = useWindowDimensions();
  const navigate = useNavigate();

  const menu = [
    {
      key: "0",
      label: "Profile Details",
      onClick: () => navigate("/"),
    },
    {
      key: "1",
      label: "Logout",
      onClick: () => logout(),
    },
  ];

  const onClick: MenuProps["onClick"] = (e: any) => {
    navigate(e.key);
    if (windowDimensions.width < 769) {
      setCollapsed(true);
      setVisible(true);
    }
  };
  console.log(location);

  //@ts-ignore
  const adminProfilePic =
    JSON.parse(localStorage.getItem("user")!)?.user?.file?.url ||
    "https://api-milano.valuescale.co.in/images/profiles/21ef19fd-a54b-4aef-bcd1-e1be4c569f68.jpg";
  const userName = JSON.parse(localStorage.getItem("user")!)?.user?.name;

  React.useEffect(() => {
    if (windowDimensions.width < 769) {
      setVisible(true);
      setCollapsed(true);
    }
    document.title = "Admin Panel";
  }, []);

  return (
    <div className={"admin-list-layout"}>
      <div className="admin-dashboard-main">
        <Layout className="admin-layout">
          <Sider
            className={`${visible ? "admin-sider-right" : "admin-sider-main"}`}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <div className="admin-sider">
              <div
                className={
                  visible ? "ant-layout-sider-collapsed" : "admin-logo"
                }
              >
                <Link to={ADMIN}>
                  <img
                    className={visible ? "ant-layout-sider-collapsed" : ""}
                    src={logo2}
                    alt=""
                  />
                </Link>
              </div>
              <div
                className={"admin-sider-title"}
                style={{ display: visible ? "none" : "block" }}
              >
                MENU
              </div>
              <Menu
                onClick={onClick}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                mode="inline"
                theme="dark"
                selectedKeys={[location]}
                items={items}
                className="admin-sider-menu"
              />
            </div>
          </Sider>

          <Layout className="site-layout">
            <Header className="site-layout-background p-0">
              <Row
                gutter={{ xs: 8, sm: 16, md: 24 }}
                align="middle"
                justify="space-between"
                className="admin-header"
              >
                <Col
                  xs={visible ? 8 : 24}
                  sm={visible ? 8 : 24}
                  md={12}
                  lg={12}
                  className="admin-header-left"
                >
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: () => {
                        setCollapsed(!collapsed);
                        setVisible(!visible);
                      },
                    }
                  )}
                  {titleSearch}
                </Col>
                <Col
                  className={`gutter-row header-col admin-header-right ${
                    visible ? "admin-right-show" : "admin-right-hide"
                  }`}
                  xs={16}
                  sm={16}
                  md={12}
                  lg={12}
                >
                  <div className="admin-avatar-container">
                    <div className="dropdown flex align-items-center">
                      <Dropdown
                        dropdownRender={(menu) => {
                          return (
                            <Button
                              className="nav-button"
                              onClick={() => logout()}
                            >
                              Log Out
                            </Button>
                          );
                        }}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <Space>
                            <>
                              <div
                                className="avatar flex align-items-center "
                                style={{ paddingRight: "10px" }}
                              >
                                <img src={avatar} alt="user" />
                              </div>
                              {userName}
                              <DownOutlined />
                            </>
                          </Space>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              </Row>
            </Header>
            <Content
              className={`manage-products-main-content p-2 ${
                visible ? "content-right-show" : "admin-right-hide"
              }`}
            >
              <Row
                className="align-items-center stock-summary-container mb-2 mt-2"
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                }}
              >
                <div className="manage-order-title">{title}</div>
              </Row>
              {children}
            </Content>
            <Footer className="p-2 admin-footer">
              <span>Copyright © DivergeHR 2024.</span>
            </Footer>
          </Layout>
        </Layout>
      </div>
    </div>
  );
}
