import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { CustomPopConfirm } from "components/CustomPopConfirm";
import moment from "moment";
import { SetStateAction, useEffect, useState } from "react";
import API_SERVICE from "services/api-service";
import { USER_STATUS } from "shared/constants/Constants";
import { exportBlob } from "shared/services/Utility";

const { RangePicker } = DatePicker;

const filterStatus = [
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Blocked",
    value: "blocked",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "In-Active",
    value: "inactive",
  },
];

const UserManagement = () => {
  const [userManagementData, setUserManagementData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [singleUserInfo, setSingleUserInfo] = useState({
    first_name:"",
    last_name:"",
    full_name: "",
    email: "",
    created_at: "",
    status: "",
  });
  const [sort, setSort] = useState({}) as any;
  const [addUserForm] = useForm();
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    from_date: null,
    to_date: null,
    status: null,
  });

  const viewInfo = (data: any) => {
    showModal();
    setDisableForm(true);
    setSingleUserInfo(data);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDisableForm(false);
    addUserForm.resetFields();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const columns: any = [
    {
      title: "Sr.no",
      render: (a: any, v: any, index: number) => (
        <div>{(page - 1) * 10 + index + 1}</div>
      ),
    },
    {
      title: "Customer Name",
      key: "name",
      sorter: (a: any, b: any) => {},
      render: (data: { first_name: string; last_name: string }) => (
        <>
          {data.first_name} {data.last_name}
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => {},
    },

    {
      title: "Creation Date & Time",
      dataIndex: "created_at",
      key: "createdAt",
      render: (data: Date) => moment(data).format("DD/MM/YYYY HH:MM:SS A"),
      sorter: (a: any, b: any) => {},
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (d: string) => <span>{d.toUpperCase()}</span>,
    },
    {
      title: "Action",

      render: (data: any) => {
        return (
          <div className="d-flex gap-2 align-items-center">
            <EyeOutlined
              style={{ fontSize: "1.7rem" }}
              onClick={() => viewInfo(data)} rev={undefined}            />

            <CustomPopConfirm
              title="Delete the user"
              description="Are you sure to delete this user?"
              onOk={() => deleteUser(data.id)}
            >
              <DeleteOutlined style={{ fontSize: "1.7rem", color: "red" }} rev={undefined} />
            </CustomPopConfirm>
            {data.status === USER_STATUS.approved ||
            data.status === USER_STATUS.active ||
            data.status === USER_STATUS.blocked ||
            data.status === USER_STATUS.inactive ? (
              <Switch
                checked={data.is_blocked}
                onChange={(checked: boolean) =>
                  userBlockUnblock(data.id, checked)
                }
              />
            ) : (
              <></>
            )}
            {data.status === USER_STATUS.pending && (
              <>
                <span style={{ fontSize: "1.7rem" }}>|</span>

                <CustomPopConfirm
                  title="Approve user"
                  description="Are you sure to approve this user?"
                  onOk={() => approveUser(data.id)}
                >
                  <Button>Approve</Button>
                </CustomPopConfirm>
                <CustomPopConfirm
                  title="Reject user"
                  description="Are you sure to reject this user?"
                  onOk={() => rejectUser(data.id)}
                >
                  <Button>Reject</Button>
                </CustomPopConfirm>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const fetchUserData = async () => {
    try {
      const params: any = {
        page: page,
        ...sort,
      };
      if (filters.email) {
        params["email"] = filters.email;
      }
      if (filters.name) {
        params["name"] = filters.name;
      }
      if (filters.from_date) {
        params["from_date"] = moment(filters.from_date).format("YYYY-MM-DD");
      }
      if (filters.to_date) {
        params["to_date"] = moment(filters.to_date).format("YYYY-MM-DD");
      }
      if (filters.status) {
        params["status"] = filters.status;
      }
      const { data } = await API_SERVICE.fetchUserManagementData(params);

      if (data) {
        setTotal(data.count);
        setUserManagementData(data.result);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const addUser = async (payload: any) => {
    try {
      const res = await API_SERVICE.addUser(payload);
      if (res)
        notification.success({
          message: "User Added Succesfully",
          placement: "topRight",
        });
      setPage(1);
      addUserForm.resetFields();
      setIsModalOpen(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "topRight",
      });
    }
  };

  const userBlockUnblock = async (id: number, checked: boolean) => {
    try {
      await API_SERVICE.userBlockUnblock(id);
      checked &&
        notification.error({
          message: `User Blocked`,
          placement: "topRight",
        });
      !checked &&
        notification.success({
          message: `User Unblocked`,
          placement: "topRight",
        });
      fetchUserData();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "topRight",
      });
    }
  };

  const approveUser = async (id: number) => {
    try {
      const { data } = await API_SERVICE.approveUser({ id });
      notification.success({
        message: data.response_message,
        placement: "topRight",
      });
      fetchUserData();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "topRight",
      });
    }
  };

  const rejectUser = async (id: number) => {
    try {
      const { data } = await API_SERVICE.rejectUser({ id });
      notification.success({
        message: data.response_message,
        placement: "topRight",
      });
      fetchUserData();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "topRight",
      });
    }
  };

  const handleFromTo = (values: any) => {
    if (values) {
      setFilters((prev: any) => {
        return {
          ...prev,
          from_date: values[0],
          to_date: values[1],
        };
      });
    } else {
      setFilters((prev: any) => {
        return {
          ...prev,
          from_date: null,
          to_date: null,
        };
      });
    }
  };

  const deleteUser = async (id: number) => {
    try {
      await API_SERVICE.deleteUser(id);
      notification.success({
        message: "User Deleted Succesfully",
        placement: "topRight",
      });
      fetchUserData();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "topRight",
      });
    }
  };

  const exportCsv = async () => {
    try {
      const params: any = { ...sort };
      if (filters.email) {
        params["email"] = filters.email;
      }
      if (filters.name) {
        params["name"] = filters.name;
      }
      if (filters.from_date) {
        params["from_date"] = filters.from_date;
      }
      if (filters.to_date) {
        params["to_date"] = filters.to_date;
      }
      if (filters.status) {
        params["status"] = filters.status;
      }
      const { data } = await API_SERVICE.fetchUserManagementCsvData(params);
      exportBlob(data);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const _handleTableChanges = async (
    pagination: { current: any; pageSize: any },
    filters: SetStateAction<{
      name: string;
      email: string;
      from_date: null;
      to_date: null;
      status: null;
    }>,
    sorter: { order: string; columnKey: any },
    extra: { currentDataSource: any; action: any }
  ) => {
    if (extra.action === "paginate") {
      setPage(pagination.current);
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
  };

  useEffect(() => {
    if (filters.name) {
      const getData = setTimeout(() => {
        fetchUserData();
      }, 500);
      return () => clearTimeout(getData);
    } else {
      fetchUserData();
    }

    // eslint-disable-next-line
  }, [page, filters, sort]);

  return (
    <Row className="p-3 d-flex flex-column gap-3">
      <Col
        xs={24}
        className="d-flex gap-3 flex-wrap justify-content-between align-items-center"
      >
        <h3>User Management</h3>
      </Col>

      <Col
        xs={24}
        className="d-flex gap-2 justify-content-between align-items-center flex-wrap"
      >
        <Row>
          <Col className="d-flex gap-2 flex-wrap w-100">
            <Input
              style={{ maxWidth: "230px" }}
              value={filters.name}
              allowClear
              size="large"
              placeholder="Search by Name"
              onChange={(e: any) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    name: e.target.value,
                  };
                });
                setPage(1);
              }}
            />
            <RangePicker
              value={[filters.from_date, filters.to_date]}
              style={{ maxWidth: "300px" }}
              allowClear
              size="large"
              onChange={(values) => handleFromTo(values)}
            />
            <Select
              value={filters.status}
              allowClear
              size="large"
              onChange={(value: any) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    status: value,
                  };
                });
              }}
              style={{ width: 200 }}
              placeholder="Select Status"
              options={filterStatus}
            />
            <Button
              size="large"
              onClick={() =>
                setFilters({
                  name: "",
                  email: "",
                  from_date: null,
                  to_date: null,
                  status: null,
                })
              }
              type="default"
            >
              Clear Filter
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-wrap gap-3">
            <Button size="large" onClick={showModal} type="primary">
              Add New User
            </Button>
            <Button size="large" onClick={exportCsv} type="default">
              Export Csv
            </Button>
          </Col>
        </Row>
      </Col>

      <Col xs={24}>
        <Table
          rowKey={"id"}
          scroll={{ x: "max-content" }}
          loading={loading}
          pagination={{
            size: "default",
            current: page,
            pageSize: 10,
            total: total,
            onChange: (page) => {
              setPage(page);
            },
          }}
          columns={columns}
          dataSource={userManagementData}
          //@ts-ignore
          onChange={_handleTableChanges}
        />
      </Col>
      <Modal
        title={disableForm ? "View User Details" : "Add New User"}
        footer={false}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        {!disableForm && (
          <Form
            form={addUserForm}
            disabled={disableForm}
            name="Add User"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={addUser}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                { required: true, message: "Please input your First Name!" },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                { required: true, message: "Please input your Last Name!" },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: "Please input your Email Address!" },
                {
                  type: "email",
                  message: "Please Enter a valid Email Address",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
        {disableForm && (
          <List>
            <List.Item>
              <b>Username</b> - {singleUserInfo && singleUserInfo.first_name} {singleUserInfo && singleUserInfo.last_name}
            </List.Item>
            <List.Item>
              <b>Email Address</b> - {singleUserInfo && singleUserInfo.email}
            </List.Item>{" "}
            <List.Item>
              <b>Creation Date & Time</b> -{" "}
              {moment(singleUserInfo && singleUserInfo.created_at).format("DD/MM/YYYY HH:MM:SS")}
            </List.Item>{" "}
            <List.Item>
              <b>Status</b> - {singleUserInfo && singleUserInfo.status.toUpperCase()}
            </List.Item>
          </List>
        )}
      </Modal>
    </Row>
  );
};

export default UserManagement;
