import { Col, Row, notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import "./Login.scss";
import LoginForm from "./LoginForm";
//@ts-ignore
import logo from "client/src/assests/images/logo.png";
import logo2 from "client/src/assests/images/logo2.svg";

import API_SERVICE from "client/src/services/api-service";
import React from "react";
import { AuthConsumer } from "shared/contexts/AuthContext";
import useWindowDimensions from "../../hooks/WindowsDimension";

interface Props {
  location: any;
}

export default function Login({ location }: Props) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Login Page";
  }, []);

  return (
    <AuthConsumer>
      {({ isAuth, user, updateAuthToken }) => {
        return isAuth ? (
          <Navigate to="/admin" />
        ) : (
          <>
            <Row>
              <Col
                xs={24}
                sm={24}
                className="h-100 min-vh-100 d-flex justify-content-center align-items-center "
              >
                <div className="login-form-wrapper  border rounded p-5 shadow-lg  h-100 flex-column mx-auto">
                  <div className="login-logo w-100 pb-4 d-flex flex-column align-items-center justify-content-center">
                    <img src={logo2} alt="logo" />
                    {/* <h1 className="login-title pt-3 pb-2">Log In</h1> */}
                  </div>

                  <LoginForm
                    onLogin={async (email: string, password: string) => {
                      setLoading(true);
                      try {
                        const { data } = await API_SERVICE.login(
                          email,
                          password
                        );
                        updateAuthToken(data);
                        notification.success({
                          message: data.response_message,
                          placement: "topRight",
                        });
                      } catch (e) {
                        notification.error({
                          message: API_SERVICE.handleErrors(e),
                          placement: "topRight",
                        });
                        setLoading(false);
                      }
                    }}
                    loading={loading}
                  />
                </div>
              </Col>
            </Row>
          </>
        );
      }}
    </AuthConsumer>
  );
}
