import { Popconfirm } from "antd";
import React from "react";

type Props = {
  children: React.ReactNode;
  title: string;
  description?: string;
  onOk: (data: any) => void;
};
export const CustomPopConfirm = ({
  children,
  onOk,
  title,
  description,
}: Props) => {
  return (
    <Popconfirm
      title={title}
      description={description}
      onConfirm={onOk}
      okText="Yes"
      cancelText="No"
    >
      {children}
    </Popconfirm>
  );
};
