import { KeyOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onLogin: (email: string, password: string) => void;
  loading: boolean;
}

export default function LoginForm({ onLogin, loading }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  function handleSubmit(values: any) {
    if (onLogin) {
      const { email, password } = values;
      onLogin(email, password);
    }
  }
  return (
    <>
      <Form
        className={"w-100 d-flex flex-column gap-4 mt-3"}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          className={"  mb-3 "}
          label={<label className="login-label">Email Id</label>}
          name="email"
          rules={[
            { required: true, message: "Please enter an email address." },
            {
              type: "email",
              message: "Invalid email address format.",
            },
          ]}
        >
          <Input
            maxLength={256}
            type="email"
            autoComplete={"off"}
            size="large"
            placeholder="Enter your Email Address"
            className="input-theme"
            prefix={<UserOutlined className="input-icon mx-2" />}
          />
        </Form.Item>
        <Form.Item
          className={"mb-3 "}
          label={<label className="login-label">Password</label>}
          name="password"
          rules={[
            { required: true, message: "Please enter a password." },
            {
              validator: (_, value) => {
                const regex =
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;

                if (regex.test(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    "Password must be 8-16 characters long and include at least one lowercase letter, one uppercase letter, one digit, and one special character."
                  );
                }
              },
            },
          ]}
        >
          <Input.Password
            autoComplete={"off"}
            size="large"
            placeholder="Enter your Password"
            prefix={<KeyOutlined className="input-icon mx-2" />}
            className="input-theme"
          />
        </Form.Item>

        <Form.Item className="mb-3">
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={loading}
            className="theme-btn"
          >
            LOGIN{" "}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
